import React from "react"
import styled from "styled-components"

import Typography from "@material-ui/core/Typography";

const Outter = styled.div`
   display: grid;
   padding-top: 32px;
   padding-bottom: 32px;
   min-height: 100vh;
   @media(min-width: 992px){
      grid-template-columns: 1fr minmax(800px, 1260px) 1fr;
   }
   @media(min-width: 768px) and (max-width: 991px){
      grid-template-columns: 0 1fr 0;
   }
   @media (min-width: 576px) and (max-width:767px) {
      grid-template-columns: 0 1fr 0;
   }
   @media (max-width: 566px){
      grid-template-columns: 0 1fr 0;
   }
`

const Inner = styled.div`
   grid-column: 2/3;
`

const PageLayout = ({ children, title, blog }) => {
   return (
      <Outter>
         <Inner>
            {
               blog ?
                  <Typography variant='h3' align='center' gutterBottom> {title} </Typography>
                  :
                  <Typography variant='h2' align='center' gutterBottom> - {title} - </Typography>
            }
            {children}
         </Inner>
      </Outter>
   )
}

export default PageLayout;