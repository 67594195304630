import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Typography from "@material-ui/core/Typography"

const StyledFooter = styled.footer`
   background-color: ${props => props.theme.grey};
   color: ${props => props.theme.offWhite};
   position: absolute;
   bottom: 0;
   width: 100%;
   padding: 8px 0px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   align-items: center;
`

const StyledLink = styled(Link)`
   text-decoration: underline;
   color: ${props => props.theme.offWhite};
   padding: 8px;
   `
const AnchorLink = styled.a`
   text-decoration: underline;
   color: ${props => props.theme.offWhite};
   padding: 8px;
`


const Footer = () => {
   return (
     <StyledFooter>
       <div>
         <Typography variant="body2" align="center" gutterBottom>
           © {new Date().getFullYear()} GRYMSTRENGTH.COM ALL RIGHTS RESERVED
           <br />
         </Typography>
       </div>
       <div>
         <Typography variant="body2" align="center" gutterBottom>
           <StyledLink to="/contact/">Contact</StyledLink>-
           <AnchorLink
             href="https://www.instagram.com/grym_strength/"
             title="Instagram"
             target="_blank"
             rel="noreferrer"
           >
             Instagram
           </AnchorLink>
           -
           <AnchorLink
             href="https://www.linkedin.com/in/david-thomas-7b8b2879/"
             title="LinkedIn"
             target="_blank"
             rel="noreferrer"
           >
             LinkedIn
           </AnchorLink>
         </Typography>
       </div>
       <div>
         <Typography variant="body2" align="center">
            Icons made by{" "}
            <AnchorLink
               href="https://www.flaticon.com/authors/good-ware"
               title="Good Ware"
               target="_blank"
               rel="noopener noreferrer"
            >
             Good Ware
            </AnchorLink>{" "}
            from{" "}
            <AnchorLink
             href="https://www.flaticon.com/"
             title="Flaticon"
             target="_blank"
             rel="noopener noreferrer"
           >
             www.flaticon.com
           </AnchorLink>
           <br/>
           Photos by{" "}
           <AnchorLink
             href="https://unsplash.com/@victorfreitas?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
             title="Victor Freitas Photos"
             target="_blank"
             rel="noopener noreferrer"
           >
             Victor Freitas
           </AnchorLink>
           on{" "}
           <AnchorLink
             href="https://unsplash.com/@victorfreitas?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
             title="Unsplash"
             target="_blank"
             rel="noopener noreferrer"
           >
             Unsplash
           </AnchorLink>
         </Typography>
       </div>
     </StyledFooter>
   )
}

export default Footer;