import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const InstagramLogoLink = () => {
   const data = useStaticQuery(graphql`
      query{
         backgroundImage: file(relativePath: { eq: "insta-white.png"}) {
            childCloudinaryAsset{
               fixed(width: 20){
                  ...CloudinaryAssetFixed
               }
            }
         }
      }
   `)
   return (
      <Img fixed={data.backgroundImage.childCloudinaryAsset.fixed} alt="Grym Strength Logo Link Home" />
   )
}

export default InstagramLogoLink;
