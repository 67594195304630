import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Button from "@material-ui/core/Button"
import HeaderLogo from "../components/HeaderLogo"
import InstagramLink from "../components/InstaLink"

const NavLink = ({path, name}) => {
   return (
      <Link to={path} style={{textDecoration: `none`}}>
         <Button style={{color:'white'}} size="large">
            {name}
         </Button>
      </Link>
   )
}

const LinkContainer = styled.div`
   margin: 0 auto;
   max-width: 1260px;
   padding: 1.45rem 1.0875rem;
   display: flex;
   justify-content: space-between;
   @media(max-width: 768px){
      flex-wrap: wrap;
      justify-content: center;
   }
`

const Header = () => (
  <header style={{ background: `#2f2e2e` }} >
      <LinkContainer>
         <Link to="/" style={{ color: `white`, textDecoration: `none`, margin: `0 32px`}}>
            <HeaderLogo />
         </Link>
         <div style={{display: 'flex', alignItems: 'center', color:'white', margin: '0 32px'}}>
            <NavLink path="/" name='Home'/>
            |
            <NavLink path="/store/" name='Store'/>
            |
            <NavLink path="/blog/" name='Blog'/>
            |
            <Link to="/instagram/" style={{color: 'white', textDecoration: 'none', padding: '8px 11px', marginTop: '4px'}}>
               <InstagramLink />
            </Link>
            |
            <NavLink path="/contact/" name='Contact'/>
         </div>
      </LinkContainer>
  </header>
)

export default Header
