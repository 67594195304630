import React from "react"
import styled, { ThemeProvider } from "styled-components"


import Header from "./header"
import Footer from "./Footer"
import "./layout.css"
import bgTexture from "../images/concrete-wall-2.png"

const theme = {
  lightgreen: "#95BF74",
  green: "#37462B",
  black: "#1C110A",
  grey: "#475B63",
  offWhite: "#F3E8EE",
  maxWidth: "1000px",
  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
  cardMaxWidth: "360px"
}
// const theme = {
//   lightgreen: "#9FCC2E",
//   black: "#2E2C2F",
//   grey: "#475B63",
//   green: "#0E402D",
//   offWhite: "#F3E8EE",
//   maxWidth: "1000px",
//   bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
//   cardMaxWidth: "360px"
// }


const MainContainer = styled.main`
  margin: 0px auto;
  padding-top: 0;
  padding-bottom: 15px;
  background-color: ${props => props.theme.offWhite};
  background-image: url(${bgTexture});
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div style={{position: 'relative', minHeight: '100vh', paddingBottom: '40px'}}>
          <Header />
          <MainContainer>
            {children}
          </MainContainer>
          <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Layout
